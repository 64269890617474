<template>
  <div>
    <a-collapse
      ref="refCollapse"
      bordered
      class="pca-sales-item"
      :style="{ minWidth: width + 'px' }"
      default-active-key="1"
      @change="onCollapse"
    >
      <a-collapse-panel key="2" :show-arrow="false">
        <template slot="header">
          <div class="list-item">
            <div class="row flex-nowrap align-items-center row-calc fs-12px">
              <div v-if="$route.meta.key.includes('sales-channel-kino')" class="col text-truncate w-8" @click="e => e.stopPropagation()">
                <a-checkbox
                  v-if="sales.sfa.response == '0'"
                  v-model="isChecked"
                  class="sales-checkbox"
                  @change="handleItemSubmitted"
                />
              </div>
              <div class="col text-truncate">
                <time
                  v-if="sales.created_at"
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="$moment(sales.created_at).format('DD MMM YYYY - HH:mm')"
                >
                  {{ $moment(sales.created_at).format('DD MMM YYYY - HH:mm') }}
                </time>
              </div>
              <div :class="[orderCol, 'text-truncate']">
                <div
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="sales.order_number"
                >
                  <a-button
                    type="link"
                    size="small"
                    class="text-dark pl-0"
                    @click="e => {
                      e.stopPropagation()
                      handleCopy('No Order', sales.order_number)
                    }"
                  >
                    <a-icon type="copy" />
                  </a-button>
                  {{ sales.order_number }}
                </div>
              </div>
              <div class="col text-truncate">
                <div
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="sales.business.business_title"
                >
                  {{ sales.business.business_title }}
                </div>
              </div>
              <div class="col text-truncate">
                <div
                  v-if="sales.channel"
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="sales.channel.sales_channel.name || sales.channel.sales_channel.channel_name"
                >
                  {{ sales.channel.sales_channel.name || sales.channel.sales_channel.channel_name }}
                </div>
              </div>
              <div class="col text-truncate">
                <div
                  v-if="sales.channel"
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="sales.channel.name || sales.channel.channel_title"
                >
                  {{ sales.channel.name || sales.channel.channel_title }}
                </div>
              </div>
              <div class="col text-truncate">
                <div
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="parseCustomerName(sales)"
                >
                  {{ parseCustomerName(sales) }}
                </div>
              </div>
              <div class="col-10p fs-12px text-truncate text-center">
                {{ sales.sales_items && sales.sales_items.length }}
              </div>
              <div class="col text-truncate">
                <!-- sub_total | order_total -->
                {{ sales.currency }}<span v-if="sales && sales.sub_total !== null" class="ml-1">{{ parseNum(sales.sub_total) }}</span>
              </div>
              <div class="col px-1 d-flex align-items-center">
                <div class="w-50">
                  <a-button
                    shape="round"
                    size="small"
                    class="text-white px-2 mr-0 fs-10px"
                    :style="{ background: getBackgroundColorStatus }"
                  >
                    {{ $t(`order.filterState.${wordingSalesState}`) }}
                    <!-- {{ sales.sales_state ? $t(`order.filterState.${sales.sales_state.toLowerCase()}`) : '' }} -->
                  </a-button>
                </div>
                <div class="w-25 text-right">
                  <template v-if="isInstantCourier">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Order ini menggunakan kurir instan</span>
                      </template>
                      <img
                        src="@/assets/courier/instant-icon.svg"
                      />
                    </a-tooltip>
                  </template>
                </div>
                <div class="w-25 text-right">
                  <div v-if="sales.confirmed === false" style="text-align: right;">
                    <a-tooltip placement="bottom">
                      <template slot="title">
                        <span>Order ini terdapat produk yang belum ter-mapping</span>
                      </template>
                      <a-icon type="info-circle" style="color: #FF0A54; font-size: 20px;" />
                    </a-tooltip>
                  </div>
                </div>
                <!-- <a-icon type="caret-down" :class="['toggleUpDown', isShow && 'rotate']" /> -->
              </div>
              <div class="" style="width: 40px !important; position: sticky; right: 0; background: #fafafa;">
                <a-dropdown placement="bottomRight">
                  <a-button
                    type="link"
                    size="small"
                    class="text-dark font-weight-bold px-0"
                    style="font-size: 20px; line-height: 0"
                    @click="e => e.stopPropagation()"
                  >
                    <a-icon type="more" />
                  </a-button>
                  <a-menu slot="overlay">
                    <a-menu-item @click="clickDetail">
                      {{ isShow ? 'Tutup' : 'Lihat' }} Detail
                    </a-menu-item>
                    <!-- NOTE: 'CREATED' for old data -->
                    <a-menu-item
                      v-if="
                        // sales.channel && sales.channel.sales_channel.code === 'distributor' && // eslint-disable-next-line
                        // sales.channel && sales.channel.sales_channel.id === 56 && // eslint-disable-next-line
                        // $route.path !== '/sales' && // eslint-disable-next-line
                        // getChannelCode === 'distributor' && // eslint-disable-next-line
                        permission.includes('WRITE') && // eslint-disable-next-line
                        (sales.sales_state === 'SUBMITTED' || sales.sales_state === 'CREATED')
                      "
                      @click="onAccepted(sales)"
                    >
                      {{ $t('order.accept_order') }}
                    </a-menu-item>
                    <!-- NOTE: 'CREATED' for old data -->
                    <a-menu-item
                      v-if="
                        // sales.channel && sales.channel.sales_channel.code === 'distributor' && // eslint-disable-next-line
                        // sales.channel && // eslint-disable-next-line
                        // sales.channel.sales_channel.id === 56 && // eslint-disable-next-line
                        // $route.path !== '/sales' && // eslint-disable-next-line
                        // getChannelCode === 'distributor' && // eslint-disable-next-line
                        permission.includes('WRITE') && // eslint-disable-next-line
                        // ['CREATED', 'SUBMITTED', 'CANCELREQUESTED', 'ACCEPTED', 'SHIPPED', 'DELIVERED'].includes(sales.sales_state)
                          ['CREATED', 'SUBMITTED', 'CANCELREQUESTED', 'VERIFIED', 'ACCEPTED'].includes(sales.sales_state)
                      "
                      @click="onClickCancelOrder(sales)"
                    >
                      {{ ['CANCELREQUESTED'].includes(sales.sales_state) ? $t('order.accept_cancel_order') : $t('order.cancel_order') }}
                    </a-menu-item>
                    <a-menu-item
                      v-if="permission.includes('WRITE') && ['CANCELREQUESTED'].includes(sales.sales_state)"
                      @click="onCancelRequest(sales)"
                    >
                      {{ $t('order.cancel_request') }}
                    </a-menu-item>
                    <a-menu-item
                      v-if="$route.params.id && ['CREATED', 'SUBMITTED', 'CANCELREQUESTED', 'VERIFIED', 'ACCEPTED'].includes(sales.sales_state) && isShowInternalCancel"
                      @click="onClickInternalCancelOrder(sales)"
                    >
                      Internal Cancel
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </div>
            </div>
          </div>
        </template>
        <div class="row overflow-auto refRowDetail">
          <div class="col-9 p-2">
            <a-skeleton
              v-if="loadingDetail"
              active
              :title="false"
              :paragraph="{ width: '100%' }"
              class="mt-3"
            />
            <template v-else>
              <template v-if="$route.meta.key.includes('sales-channel-kino')">
                <div class="pbz-font body-sm-bold px-2">
                  SFA
                </div>
                <div class="form-row px-2 mt-1">
                  <div class="col-2 text-muted">
                    Tanggal
                  </div>
                  <div class="col-10 pbz-font body-sm-regular">
                    : <span v-if="sales.sfa.created_at">
                      {{ $moment(sales.sfa.created_at).format('DD MMM YYYY - HH:mm') }}
                    </span>
                  </div>
                  <div class="col-2 text-muted">
                    Status
                  </div>
                  <div class="col-10 pbz-font body-sm-regular">
                    : <span v-if="sales.sfa.response" :class="sales.sfa.response == '1' ? 'text-success' : 'text-danger'">
                      {{ sales.sfa.response == '1' ? $t('utils.success') : $t('utils.failed') }}
                      <a-icon
                        v-if="sales.sfa.response_code != '200'"
                        type="info-circle"
                        theme="twoTone"
                        two-tone-color="#f5222d"
                        @click="$emit('handleSfaDetail', { ...sales.sfa, order_number: sales.order_number })"
                      />
                    </span>
                  </div>
                </div>
              </template>
              <hr v-if="$route.meta.key.includes('sales-channel-kino')" />
              <div
                v-for="(salesItem, salesItemIndex) in sales_items"
                :key="salesItemIndex"
                :class="[!$route.meta.key.includes('sales-channel-kino') ? 'py-3' : '', 'form-row']"
              >
                <div v-if="sales.sales_items" class="col-1">
                  <PcaImage
                    height="48"
                    width="48"
                    class="of-cover"
                    alt=""
                    :src="salesItem.img_url"
                  />
                </div>
                <div class="col-2 text-muted">
                  <div v-if="sales.sales_items">
                    <small>{{ $t('order.productName') }}</small>
                    <h6 v-if="salesItem.title" class="mb-0 pbz-font body-sm-regular">
                      {{ salesItem.title }}
                    </h6>
                  </div>
                  <h6 v-if="!salesItem.title" class="mb-0 pbz-font body-sm-regular">
                    Title data is null
                  </h6>
                </div>
                <div v-if="sales.sales_items" class="col-2 text-muted">
                  <small>{{ $t('order.skuTitle') }}</small>
                  <h6 class="mb-0 pbz-font body-sm-regular text-break-word">
                    <a-button
                      type="link"
                      size="small"
                      class="text-dark"
                      @click="e => {
                        e.stopPropagation()
                        handleCopy('No SKU', salesItem.catalog_sku)
                      }"
                    >
                      <a-icon type="copy" />
                    </a-button>
                    {{ salesItem.catalog_sku }}
                  </h6>
                </div>
                <div class="col-1 mt-2">
                  <a-tooltip v-if="salesItem.confirmed === false" placement="bottom">
                    <template slot="title">
                      <span>Produk ini belum ter-mapping</span>
                    </template>
                    <a-icon type="info-circle" style="color: #FF0A54; font-size: 20px;" />
                  </a-tooltip>
                </div>
                <div v-if="sales.sales_items" class="col-1 text-muted">
                  <small>{{ $t('order.quantityTitle') }}</small>
                  <h6 class="mb-0 pbz-font body-sm-regular">
                    {{ `${salesItem.item_qty} ${salesItem.item_uom}` }}
                  </h6>
                </div>
                <div v-if="sales.sales_items" class="col-2 text-muted">
                  <small>{{ $t('order.priceTitle') }}</small>
                  <h6 class="mb-0 pbz-font body-sm-regular">
                    {{ salesItem.currency }} {{ parseNum(salesItem.selling_price || 0) }}
                  </h6>
                </div>
                <div v-if="sales.sales_items" class="col-1 text-muted">
                  <small>{{ $t('order.discountTilte') }}</small>
                  <!-- {{ checkDiscountType(sales.discount_type, sales.discount) }} -->
                  <h6 class="mb-0 pbz-font body-sm-regular">
                    {{ salesItem.currency }}
                    {{ parseNum(salesItem.discount) }}<!-- sales.discount -->
                  </h6>
                </div>
                <div v-if="sales.sales_items" class="col-2 text-muted">
                  <small>{{ $t('order.totalTitle') }}</small>
                  <h6 class="mb-0 pbz-font body-sm-bold">
                    {{ sales.currency }}
                    {{ parseNum(salesItem.sub_total || 0) }}
                  </h6>
                </div>
              </div>
            </template>
            <hr />
            <!-- bawah barang -->
            <div class="px-2">
              <a-skeleton :loading="loadingDetail" active :paragraph="{ width: '100%', rows: 2 }">
                <div class="pbz-font body-sm-bold">
                  {{ $t('order.detailsTitle') }}
                </div>
                <transition name="fade">
                  <div v-if="isHiddenRincian">
                    <div class="row justify-content-between mt-2">
                      <template v-if="value && value.payment">
                        <div class="col-9">
                          Status Pembayaran
                        </div>
                        <div class="col-3">
                          {{ value.payment.status }}
                        </div>
                        <div class="col-9 mt-2">
                          Metode Pembayaran
                        </div>
                        <div class="col-3 mt-2">
                          {{ value.payment.term_title }}
                        </div>
                        <div class="col-9 mt-2">
                          Voucher
                        </div>
                        <div class="col-3 mt-2">
                          -
                        </div>
                        <hr class="hr" />
                        <div class="col-9">
                          Total Harga
                        </div>
                        <div class="col-3 pbz-font">
                          {{ sales.currency }} {{ sales.order_total }}
                        </div>
                        <div class="col-9 mt-3">
                          Total Ongkos Kirim
                        </div>
                        <div class="col-3 mt-3">
                          -
                        </div>
                      </template>
                      <template v-else>
                        <div class="col-3 mt-2">
                          There is no data to show
                        </div>
                      </template>
                    </div>
                  </div>
                </transition>

                <div class="form-row mt-2">
                  <div class="col-9">
                    {{ $t('order.totalTitle') }}
                  </div>
                  <div class="col-3">
                    <!-- order_total -->
                    <span v-if="sales.sub_total" class="pbz-font body-sm-bold summary-order">
                      <p>{{ sales.currency }}</p>
                      {{ parseNum(sales.sub_total) }}
                    </span>
                    <span v-else><p>{{ sales.currency }} 0</p></span>
                  </div>
                </div>

                <div v-if="sales.discount" class="form-row mt-2">
                  <div class="col-9">
                    {{ $t('order.transaction_discount') }}
                  </div>
                  <div class="col-3">
                    <span class="pbz-font body-sm-bold summary-order">
                      <p>{{ sales.currency }}</p>
                      {{ parseNum(sales.discount) }}
                    </span>
                  </div>
                </div>
                <div v-for="(promo, index) in sales.promotions?.discounts" v-else :key="index">
                  <div v-if="promo.source === 'seller' && promo.value" class="form-row mt-2">
                    <div class="col-9">
                      {{ promo.type === 'transaction' ? $t('order.discount_transaction') : $t('order.discount_shipping') }} {{ textDiscountFrom(promo.source) }}
                    </div>
                    <div class="col-3">
                      <span class="pbz-font body-sm-bold summary-order">
                        <p>{{ sales.currency }}</p>
                        {{ parseNum(promo.value) }}
                      </span>
                    </div>
                  </div>
                </div>

                <div v-if="sales.voucher_amount" class="form-row mt-2">
                  <div class="col-9">
                    {{ $t('order.transaction_voucher') }}
                  </div>
                  <div class="col-3">
                    <span class="pbz-font body-sm-bold summary-order">
                      <p>{{ sales.currency }}</p>
                      {{ parseNum(sales.voucher_amount) }}
                    </span>
                  </div>
                </div>

                <hr />

                <div v-for="(promo, index) in sales.promotions?.discounts" :key="index">
                  <div v-if="promo.source !== 'seller' && promo.value" class="form-row mt-2">
                    <div class="col-9 text-muted">
                      {{ promo.type === 'transaction' ? $t('order.discount_transaction') : $t('order.discount_shipping') }} {{ textDiscountFrom(promo.source) }}
                    </div>
                    <div class="col-3">
                      <span class="pbz-font body-sm-bold summary-order text-muted">
                        <p>{{ sales.currency }}</p>
                        {{ parseNum(promo.value) }}
                      </span>
                    </div>
                  </div>
                </div>

                <div v-if="sales.loyalty_usage" class="form-row mt-2">
                  <div class="col-9 text-muted">
                    {{ $t('order.transaction_loyalty') }}
                  </div>
                  <div class="col-3">
                    <span class="pbz-font body-sm-bold summary-order text-muted">
                      <p />
                      {{ parseNum(sales.loyalty_usage) }}
                    </span>
                  </div>
                </div>

                <div v-if="sales.deposit" class="form-row mt-2">
                  <div class="col-9 text-muted">
                    {{ $t('order.transaction_deposit') }}
                  </div>
                  <div class="col-3">
                    <span class="pbz-font body-sm-bold summary-order text-muted">
                      <p>{{ sales.currency }}</p>
                      {{ parseNum(sales.deposit) }}
                    </span>
                  </div>
                </div>

                <div v-if="(shipping_items?.shipping_cost + shipping_items?.insurance_cost) > 0" class="form-row mt-2">
                  <div class="col-9 text-muted">
                    {{
                      shipping_items?.shipping.courier_name === 'SPU'
                        ? 'Pick Up Admin'
                        : $t('order.transaction_shipping')
                    }}
                  </div>
                  <div class="col-3">
                    <span class="pbz-font body-sm-bold summary-order text-muted">
                      <p>{{ shipping_items?.currency }}</p>
                      {{ parseNum(shipping_items?.shipping_cost + shipping_items?.insurance_cost) }}
                    </span>
                  </div>
                </div>
                <div v-if="((shipping_items_backup?.shipping?.insuranceCost?.value || 0) + (shipping_items_backup?.shipping?.shippingCost?.value + 0)) > 0" class="form-row mt-2">
                  <div class="col-9 text-muted">
                    {{ $t('order.transaction_shipping') }}
                  </div>
                  <div class="col-3">
                    <span class="pbz-font body-sm-bold summary-order text-muted">
                      <p>{{ shipping_items_backup.currency }}</p>
                      {{ parseNum((shipping_items_backup?.shipping?.insuranceCost?.value || 0) + (shipping_items_backup?.shipping?.shippingCost?.value + 0)) }}
                    </span>
                  </div>
                </div>

                <div v-if="sales?.buyer_admin_fee?.total" class="form-row mt-2">
                  <div class="col-9 text-muted">
                    {{ $t('order.transaction_admin') }}
                  </div>
                  <div class="col-3">
                    <span class="pbz-font body-sm-bold summary-order text-muted">
                      <p>{{ sales.currency }}</p>
                      {{ parseNum(sales.buyer_admin_fee.total) }}
                    </span>
                  </div>
                </div>

                <!-- <div class="form-row mt-2">
                  <div class="col-9">
                    {{ $t('order.paymentsTotalTitle') }}
                  </div>
                  <div class="col-3">
                    <span v-if="sales.sub_total" class="pbz-font body-sm-bold summary-order">
                      <p>{{ sales.currency }}</p>
                      {{
                        parseNum(
                          sales.sub_total
                            - sales.discount
                            - sales.deposit
                            - sales.loyalty_usage
                            - sales.voucher_amount
                            - valueTotalDiscountFromChannelAndSeller
                            + (sales?.buyer_admin_fee?.total || 0)
                        ) }}
                    </span>
                    <span v-else><p>{{ sales.currency }} 0</p></span>
                  </div>
                </div> -->
                
                <!-- @TODO: Service endpoint not ready -->
                <!-- Toggle Buka tutup  rincian -->
                <!-- <a
                  class="pbz-font text-primary d-inline-block my-2"
                  @click.prevent="toggleView(sales.id)"
                >
                  {{ textRincian }}
                </a> -->
                <!-- akhir detail rincian pembelian -->
                <!-- @TODO: -->
                <!-- <hr>
                <a @click.prevent="showModalPerubahan" class="my-2">
                  <a-icon type="history" /> {{ $t('order.orderDate') }}
                </a> -->
              </a-skeleton>
            </div>
            <div v-if="$route.params.id">
              <template v-if="sales?.payment_data">
                <div class="pl-2 mt-4">
                  <h6 class="pbz-font body-sm-bold mb-1">
                    Metode Pembayaran
                  </h6>
                  <div>{{ printPaymentMethod }}</div>
                </div>
              </template>
              <template v-if="cadBillData">
                <div class="pl-2 mt-4">
                  <h6 class="pbz-font body-sm-bold mb-1">
                    Detail CAD
                  </h6>
                  <div class="form-row px-0 mt-1">
                    <div class="col-2 text-muted">
                      Tgl Jatuh Tempo
                    </div>
                    <div class="col-10 pbz-font body-sm-regular">
                      : <span v-if="cadBillData.credit_limit_due_date">
                        {{ $moment(cadBillData.credit_limit_due_date).format('DD MMM YYYY - HH:mm') }}
                      </span>
                    </div>
                    <div class="col-2 text-muted">
                      Status Tagihan
                    </div>
                    <div class="col-10 pbz-font body-sm-regular">
                      : <span v-if="cadBillData.credit_limit_status">
                        {{ cadBillData.credit_limit_status?.toUpperCase() }}
                      </span>
                    </div>
                  </div>
                </div>
              </template>
              <template>
                <div v-if="['CANCELED'].includes(sales?.sales_state) && sales.reason" class="pl-2">
                  <br /><br />
                  <div class="container-cancel-info">
                    <b>*Dibatalkan oleh pembeli: </b><span class="ml-1">{{ sales.reason }}</span>
                  </div>
                </div>
                <div v-else-if="['CANCELED'].includes(sales?.sales_state) && sales.cancelled_reason" class="pl-2">
                  <br /><br />
                  <div class="container-cancel-info">
                    <b>*Dibatalkan oleh penjual: </b><span class="ml-1">{{ sales.cancelled_reason }}</span>
                  </div>
                </div>
                <div v-else-if="['REJECTED'].includes(sales?.sales_state) && sales.reason" class="pl-2">
                  <br /><br />
                  <div class="container-cancel-info">
                    <b>*Pembatalan oleh sistem: </b><span class="ml-1">{{ sales.reason }}</span>
                  </div>
                </div>
                <div v-else-if="['CANCELEDPAYMENTEXPIRED'].includes(sales?.sales_state) && sales.reason" class="pl-2">
                  <br /><br />
                  <div class="container-cancel-info">
                    <b>*Pembatalan oleh sistem: </b><span class="ml-1">{{ sales.reason }}</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="col-3 py-3 bg-white border-left">
            <!-- pengiriman -->
            <a-skeleton :loading="loadingDetail" active :paragraph="{ width: '100%' }">
              <h5 class="pbz-font body-sm-bold mb-1">
                {{ $t('order.shippingTitle') }}
              </h5>
              <div class="text-left mt-3">
                <a-spin :spinning="loadingShippingAddressBackup" tip="Mohon tunggu...">
                  <div v-if="Object.keys(shipping_items || shipping_items_backup || {} ).length > 1">
                    <div class="mt-4">
                      <h6 v-if="shipping_items?.shipping.courier_name === 'SPU'" class="pbz-font body-sm-bold mb-1">
                        <b>Self Pickup</b>
                      </h6>
                      <h6 v-else class="text-muted custom_title_ship_text">
                        {{ $t('order.shippingWarehouseTitle') }}:
                      </h6>
                      <!-- {{ shipping_items?.destination.city }} -->
                      <template v-if="shipping_items?.shipping.courier_name !== 'SPU'">
                        {{ sales.warehouseName || '' }}
                        <p class="text-left font-1">
                          {{ sales.warehouseAddress ?
                            [
                              sales.warehouseAddress.street_name,
                              sales.warehouseAddress.sub_district_name,
                              sales.warehouseAddress.district_name,
                              sales.warehouseAddress.city_name,
                              sales.warehouseAddress.province_name,
                              sales.warehouseAddress.country_name,
                            ].join(', ') : '' }}
                        </p>
                      </template>
                    </div>
                    <div class="mt-4">
                      <h6 class="text-muted custom_title_ship_text">
                        {{ 
                          shipping_items?.shipping.courier_name === 'SPU'
                            ? $t('order.pickup_address')
                            : $t('order.shippingAddress')
                        }}:
                      </h6>
                      <template v-if="shipping_items?.shipping.courier_name === 'SPU'">
                        {{ sales.warehouseName || '' }}
                        <p class="text-left font-1">
                          {{ sales.warehouseAddress ?
                            [
                              sales.warehouseAddress.street_name,
                              sales.warehouseAddress.sub_district_name,
                              sales.warehouseAddress.district_name,
                              sales.warehouseAddress.city_name,
                              sales.warehouseAddress.province_name,
                              sales.warehouseAddress.country_name,
                            ].join(', ') : '' }}
                        </p>
                      </template>
                      <template v-else>
                        <template v-if="shipping_items?.destination">
                          <h6 class="pbz-font body-sm-bold mb-1">
                            <b>{{ maskSensitiveText(shipping_items?.destination.receiver_contact) }}</b><br />
                          </h6>
                          <span class="text-left pbz-font body-sm-bold font-1">
                            {{ maskSensitiveText(shipping_items?.destination.receiver_phone, 3, 3) }}
                          </span>
                          <p class="text-left font-1">
                            {{ shipping_items?.destination ?
                              maskSensitiveText(
                                [
                                  shipping_items?.destination.address_line1,
                                  shipping_items?.destination.address_line2,
                                ].filter((v) => v && v.length > 0).join(', ')
                              ) : '' }}
                            {{ shipping_items?.destination ?
                              [
                                shipping_items?.destination.city,
                                shipping_items?.destination.province,
                                shipping_items?.destination.postal_code,
                                shipping_items?.destination.country,
                              ].filter((v) => v && v.length > 0).join(', ') : '' }}
                          </p>
                        </template>
                        <template v-else-if="shipping_items_backup?.ship_to">
                          <h6 class="pbz-font body-sm-bold mb-1">
                            <b>{{ shipping_items_backup?.ship_to.name }}</b><br />
                          </h6>
                          <span class="text-left pbz-font body-sm-bold font-1">
                            {{ shipping_items_backup?.ship_to.phone }}
                          </span>
                          <p class="text-left font-1">
                            {{ shipping_items_backup?.ship_to?.address ?
                              [
                                shipping_items_backup?.ship_to?.address?.line1,
                                shipping_items_backup?.ship_to?.address?.line2,
                                shipping_items_backup?.ship_to?.address?.city,
                                shipping_items_backup?.ship_to?.address?.province,
                                shipping_items_backup?.ship_to?.address?.postal_code,
                                shipping_items_backup?.ship_to?.address?.country,
                              ].join(', ') : '' }}
                          </p>
                        </template>
                      </template>
                    </div>
                    <template v-if="shipping_items?.shipping.courier_name !== 'SPU'">
                      <div v-if="sales.dropshipper" class="mt-4">
                        <h6 class="text-muted custom_title_ship_text">
                          {{ $t('order.dropshipperInformation') }}:
                        </h6>
                        <h6 class="pbz-font body-sm-bold mb-1">
                          <b>{{ sales?.dropshipper?.name }}</b><br />
                        </h6>
                        <span class="text-left pbz-font body-sm-bold font-1">
                          {{ sales?.dropshipper?.phone }}
                        </span>
                      </div>
                    </template>
                    <template>
                      <div v-if="isHiddenRincianShp" class="mt-4">
                        <div>
                          <h6 class="text-muted custom_title_ship_text">
                            {{ $t('order.shippingCourierTitle') }}:
                          </h6>
                          <!-- {{ [shipping_items?.shipping.courier_name, shipping_items?.shipping.service_name, shipping_items?.shipping.service_type].filter(Boolean).join(' - ') }} -->
                          <!-- {{ sales.courier_name }} -->
                          <template v-if="shipping_items?.shipping">
                            {{ `${shipping_items?.shipping?.courier_name || ''}
                            ${(shipping_items?.shipping?.courier_name && (shipping_items?.shipping?.service_name || shipping_items?.shipping?.service_type )) ? '-' : ''}
                            ${shipping_items?.shipping?.service_name || ''}
                            ${shipping_items?.shipping?.service_type || ''}` }}
                            {{ (!shipping_items?.shipping?.courier_name && !shipping_items?.shipping?.service_name && !shipping_items?.shipping?.service_type) ? '-' : '' }}
                          </template>
                          <template v-else-if="shipping_items_backup?.shipping">
                            {{ `${shipping_items_backup?.shipping?.courier || ''}
                            ${(shipping_items_backup?.shipping?.courier && (shipping_items_backup?.shipping?.serviceName || shipping_items_backup?.shipping?.serviceType )) ? '-' : ''}
                            ${shipping_items_backup?.shipping?.serviceName || ''}
                            ${shipping_items_backup?.shipping?.serviceType || ''}` }}
                            {{ (!shipping_items_backup?.shipping?.courier && !shipping_items_backup?.shipping?.serviceName && !shipping_items_backup?.shipping?.serviceType) ? '-' : '' }}
                          </template>
                        </div>
                        <div v-if="shipping_items?.shipping.courier_name !== 'SPU'" class="mt-3">
                          <h6 class="text-muted custom_title_ship_text">
                            {{ $t('order.shippingReceiptNumber') }}:
                          </h6>
                          <!-- class="text-primary" -->
                          <span v-if="shipping_items?.awb">
                            {{ shipping_items?.awb }}
                          </span>
                          <span v-else-if="shipping_items_backup?.shipping">
                            {{ shipping_items_backup?.shipping.awb }}
                          </span>
                          <span v-else>-</span>
                        </div>
                        <div v-if="shipping_items?.shipping.courier_name !== 'SPU'" class="mt-3">
                          <h6 class="text-muted custom_title_ship_text">
                            {{ $t('order.shippingLabel') }}:
                          </h6>
                          <span v-if="shipping_items?.shipping_label_url">
                            <a :href="shipping_items?.shipping_label_url" target="_blank" style="text-decoration: underline;">
                              <a-button type="secondary">
                                <a-icon type="file" />
                                View Shipping Label
                              </a-button>
                            </a>
                          </span>
                          <span v-else-if="shipping_items_backup?.shipping">
                            <a :href="shipping_items_backup?.shipping.shippingLabelUrl" target="_blank" style="text-decoration: underline;">
                              <a-button type="secondary">
                                <a-icon type="file" />
                                View Shipping Label
                              </a-button>
                            </a>
                          </span>
                          <span v-else>-</span>
                        </div>
                        <a-divider />
                        <div class="d-flex align-items-center" style="gap: 10px;">
                          <div class="d-flex flex-column align-items-center justify-items-center">
                            <h6 class="text-muted custom_title_with_icon mb-1 text-center">
                              Delivery Status
                            </h6>
                            <ShippingIcon style="height: 100%; width: 40px;" />
                          </div>
                          <div class="text-center mt-2">
                            <div class="do_state" style="width: 60px;">
                              Menunggu
                            </div>
                            <CheckedStateIcon v-if="['CREATED', 'ACCEPTED', 'PICK_CONFIRMED', 'PACKING_COMPLETED', 'GOOD_ISSUED'].includes(fulfillmentData?.state)" />
                            <UncheckedStateIcon v-else />
                          </div>
                          <div class="text-center mt-2">
                            <div class="do_state" style="width: 60px;">
                              Picking
                            </div>
                            <CheckedStateIcon v-if="['PICK_CONFIRMED', 'PACKING_COMPLETED', 'GOOD_ISSUED'].includes(fulfillmentData?.state)" />
                            <UncheckedStateIcon v-else />
                          </div>
                          <div class="text-center mt-2">
                            <div class="do_state" style="width: 60px;">
                              Packing
                            </div>
                            <CheckedStateIcon v-if="['PACKING_COMPLETED', 'GOOD_ISSUED'].includes(fulfillmentData?.state)" />
                            <UncheckedStateIcon v-else />
                          </div>
                          <div class="text-center mt-2">
                            <div class="do_state" style="width: 60px;">
                              Good Issue
                            </div>
                            <CheckedStateIcon v-if="['GOOD_ISSUED'].includes(fulfillmentData?.state)" />
                            <UncheckedStateIcon v-else />
                          </div>
                        </div>

                        <div v-if="shipping_items?.shipping.courier_name !== 'SPU'" class="mt-3 d-flex align-items-center" style="gap: 10px;">
                          <div class="d-flex flex-column align-items-center justify-items-center">
                            <h6 class="text-muted custom_title_with_icon mb-1 text-center">
                              Shipping Status
                            </h6>
                            <DeliveryIcon style="height: 100%; width: 50px;" />
                          </div>
                          <div class="text-center mt-2">
                            <div class="do_state" style="width: 60px;">
                              Menunggu
                            </div>
                            <CheckedStateIcon v-if="['CREATED', 'BOOKED', 'SHIPPED', 'DELIVERED', 'BUYER_ACCEPTED'].includes(sales.shipping_state) && fulfillmentData?.state === 'GOOD_ISSUED'" />
                            <UncheckedStateIcon v-else />
                          </div>
                          <div class="text-center mt-2">
                            <div class="do_state" style="width: 60px;">
                              Pengiriman
                            </div>
                            <CheckedStateIcon v-if="['SHIPPED', 'DELIVERED', 'BUYER_ACCEPTED'].includes(sales.shipping_state) && fulfillmentData?.state === 'GOOD_ISSUED'" />
                            <UncheckedStateIcon v-else />
                          </div>
                          <div class="text-center mt-2">
                            <div class="do_state" style="width: 60px;">
                              Sampai
                            </div>
                            <CheckedStateIcon v-if="['DELIVERED', 'BUYER_ACCEPTED'].includes(sales.shipping_state) && fulfillmentData?.state === 'GOOD_ISSUED'" />
                            <UncheckedStateIcon v-else />
                          </div>
                          <div class="text-center mt-2">
                            <div class="do_state" style="width: 60px;">
                              Selesai
                            </div>
                            <CheckedStateIcon v-if="['BUYER_ACCEPTED'].includes(sales.shipping_state) && fulfillmentData?.state === 'GOOD_ISSUED'" />
                            <UncheckedStateIcon v-else />
                          </div>
                        </div>
                      </div>
                    </template>
                    <div class="mt-4">
                      <a
                        class="pbz-font text-primary"
                        @click.prevent="toggleViewShipping"
                      >
                        {{ textRincianShp }}
                      </a>
                    </div>
                  </div>
                  <h6 v-else>
                    Data is being processed, please try again in a few moments.
                  </h6>
                </a-spin>
              </div>
              <!-- @TODO: Riwayat Rincian -->
              <!-- <div class="mt-4">
                <a class="mt-2" @click.prevent="showModalPengiriman">
                  <a-icon type="car" /> {{ $t('order.shippingHistoryTitle') }}
                </a>
              </div> -->
            </a-skeleton>
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse>
    <!-- <a-button
      type="link"
      size="small"
      class="text-dark font-weight-bold ml-1"
      @click="e => e.stopPropagation()"
    >
      ⁝
    </a-button> -->

    <!-- @TODO: -->
    <!-- <ModalPengiriman
      :visible="visiblePengiriman"
      @cancel="onCancelModalPengiriman"
    />
    <ModalPerubahan
      :visible="visiblePerubahan"
      @cancel="onCancelModalPerubahan"
    /> -->
  </div>
</template>

<script>
import { getShippingOrder } from '@/api/shipping'
import { getFulfillmentDetailByOrder, getCatalogFulfillment } from '@/api/fulfillment'
import { getDetailOrderCAD } from '@/api/channels/distributor'
import { getOrderCatalog } from '@/api/product'
import { getShippingOrderFromDashboard } from '@/api/dashboard'
import { getFinanceDetail } from '@/api/channels/distributor'
import PcaImage from '@/components/Image'
import ShippingIcon from '@/components/Icons/ShippingIcon'
import DeliveryIcon from '@/components/Icons/DeliveryIcon'
import CheckedStateIcon from '@/components/Icons/CheckedStateIcon'
import UncheckedStateIcon from '@/components/Icons/UncheckedStateIcon'
import ShippingInstantIcon from '@/assets/courier/instant-icon.svg'

// import ModalPengiriman from '@/components/Sales/Detail/RiwayatPengiriman/Modal.vue'
// import ModalPerubahan from '@/components/Sales/Detail/RiwayatPerubahan/Modal.vue'
export default {
  components: {
    PcaImage,
    ShippingIcon,
    DeliveryIcon,
    CheckedStateIcon,
    UncheckedStateIcon,
    ShippingInstantIcon,
    // ModalPengiriman,
    // ModalPerubahan,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    sales: {
      type: Object,
      default: () => {},
    },
    salesNum: {
      type: Number,
      default: 0,
    },
    shipping: {
      type: Array,
      default: () => [],
    },
    width: {
      type: Number,
      default: 1190,
    },
    permission: {
      type: Array,
      default: () => [],
    },
    orderLength: {
      type: Number,
      default: 0,
    },
    temp: {
      type: Array,
      default: () => [],
    },
    businessId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visiblePengiriman: false,
      visiblePerubahan: false,
      showed_product: [],
      isShow: false,
      isChecked: false,
      isHiddenRincian: false,
      isHiddenRincianShp: false,
      textRincian: this.$t('order.moreDetailsTitle'),
      textRincianShp: this.$t('order.moreDetailsTitle'),
      sales_items: [],
      shipping_items: [],
      shipping_items_backup: [],
      shippings: [],
      data: [],
      value: [],
      loadingDetail: false,
      controllerOrderCatalog: null,
      controllerShipping: null,
      controllerSfa: null,
      fulfillmentData: null,
      cadBillData: null,
      loadingShippingAddressBackup: false,
    }
  },
  computed: {
    isInstantCourier() {
      const instantName = ['inst', 'same']
      const findName = instantName.find(item => this.sales?.courier_name?.toLowerCase()?.includes(item))
      if (findName) {
        return true
      }
      return false
    },
    isShowInternalCancel() {
      return this.$store.state.user?.role_id === '73ed8e78-bb8f-4d90-8547-d5fb54d44b82'
    },
    getBackgroundColorStatus() {
      const sales_state = this.sales.sales_state
      if (!sales_state) {
        return null
      }

      switch (sales_state.toLowerCase()) {
        case 'submitted': // baru
        case 'created': // OPTION: old data
          return '#0A89FF' // old #0559CB
        case 'completed':
          return '#454655' // old #0091A8
        case 'accepted':
        // case 'submitted':
          return '#00BBD9'
        case 'verified':
          return '#8A8EBA' // old #04449A
        case 'delivered': // Terkirim
          return '#2D9500'
        // Batal / Dibatalkan
        case 'canceled':
        case 'cancelled':
        // case 'rejected': // ??? Value From BE = 'REJECTED'
          return '#E00000'
        default:
          return '#EC4426'
      }
    },
    wordingSalesState() {
      const sales_state = this.sales.sales_state
      if (!sales_state) {
        return null
      }

      switch (sales_state.toLowerCase()) {
        case 'verified': // belum dibayar
          return 'verified'
        case 'submitted': // baru
        case 'created': // OPTION: old data
          return 'new'
        case 'accepted': // Dalam Proses
          return 'onprocess'
        case 'shipped': // Pengiriman (en: 'On Delivery')
          return 'shipped'
        case 'delivered': // Terkirim
          return 'send'
        case 'completed': // Selesai
          return 'completed'
        // Batal / Dibatalkan
        case 'canceled':
        case 'cancelled':
        case 'rejected': // ??? Value From BE = 'REJECTED'
        case 'canceledpaymentexpired':
          return 'canceled'
        // case 'rejected': // Pembatalan pesanan
        //   return 'rejected'
        case 'cancelrequested':
          return 'cancelrequested'
        default:
          return null
      }
    },
    orderCol() {
      return 'col'
      // return this.orderLength < 16
      //   ? 'col'
      //   : this.orderLength > 15 && this.orderLength < 20
      //   ? 'col-2'
      //   : 'col-3'
    },
    valueTotalDiscountFromChannelAndSeller() {
      const sumAllDiscountValues = this.sales.promotions?.discounts?.reduce((acc, o) => {
        const value = o.source === 'seller' ? o.value : 0
        return acc + parseInt(value)
      }, 0)
      return sumAllDiscountValues || 0
    },
    printPaymentMethod() {
      const paymentData = this.sales.payment_data
      let printedPaymentData = [
        paymentData.payment_type,
        paymentData.payment_name,
        paymentData.number,
        `${paymentData.gross_amount.currency} ${this.parseNum(paymentData.gross_amount.value - (paymentData.credit_limit_usage || 0))}`,

      ]
      if (paymentData.term.toUpperCase() ==='CAD') {
        if (paymentData.mix_payment_method_id) {
          return `${paymentData.term} MIX - ${this.parseNum(paymentData.credit_limit_usage)}, ${printedPaymentData.filter(item => item).join(' - ')}`
        }
        return `${paymentData.term} - ${this.parseNum(paymentData.credit_limit_usage)}`
      }
      if (paymentData.term.toUpperCase() !== paymentData.payment_type.toUpperCase()) {
        printedPaymentData = [paymentData.term, ...printedPaymentData]
      }
      return `${printedPaymentData.filter(item => item).join(' - ')}`
    },
  },
  watch: {
    temp(value) {
      if(this.$route.meta.key.includes('sales-channel-kino')) {
        this.isChecked = value.includes(this.sales.id)
      }
    },
  },
  methods: {
    textDiscountFrom(data) {
      if (data === 'channel') {
        return this.sales?.channel?.sales_channel?.name || 'Channel'
      } else if (data === 'seller'){
        return this.sales?.business?.business_title || 'Seller'
      }
      return data
    },
    parseNum(val) {
      return val.toLocaleString(this.$store.state?.settings?.locale || 'id-ID')
    },
    onAccepted(data) {
      this.$emit('onClickAccepted', data)
    },
    onClickCancelOrder(data) {
      this.$emit('onClickCancelOrder', data)
    },
    onClickInternalCancelOrder(data) {
      this.$emit('onClickInternalCancelOrder', data)
    },
    onCancelRequest(data) {
      this.$emit('onCancelRequest', data)
    },
    parseCustomerName(sales) {
      const { customer_name, channel } = sales
      const { code } = channel
      let customerName = customer_name.trim()

      if (code === 'shopee_id') {
        customerName = this.maskSensitiveText(customerName)
      }
      return customerName ? customerName : '-'
    },
    maskSensitiveText(sensitiveText, startChars = 1, endChars = 1) {
        const { code } = this.sales.channel
        if (code !== 'shopee_id') {
            return sensitiveText
        }
        if (typeof sensitiveText !== 'string' || sensitiveText.trim().length === 0) {
            return '-'
        }

        const segments = sensitiveText.split(' ');

        const maskedSegments = segments.map(segment => {
          const length = segment.length;
          const maskChars = Math.max(length - startChars - endChars, 0);
          const visibleStart = segment.slice(0, startChars);
          const visibleEnd = segment.slice(-endChars);
          const maskedPart = '*'.repeat(maskChars);
          return visibleStart + maskedPart + visibleEnd;
        });

        // Join the masked segments back into a single string
        return maskedSegments.join(' ');
    },
    async toggleView(id) {
      this.isHiddenRincian = !this.isHiddenRincian
      if (this.isHiddenRincian) {
        this.textRincian = this.$t('order.lessDetailsTitle')
        await getInvoiceByOrderId({
          id,
          business_id: this.$route.query.business_id || this.businessId,
        })
        .then((response) => this.value = response?.data || {})
      } else {
        this.textRincian = this.$t('order.moreDetailsTitle')
      }
    },
    toggleViewShipping() {
      this.isHiddenRincianShp = !this.isHiddenRincianShp
      this.textRincianShp = this.$t(`order.${this.isHiddenRincianShp ? 'lessDetailsTitle' : 'moreDetailsTitle'}`)
    },
    onCancelModalPengiriman() {
      this.visiblePengiriman = false
    },
    onCancelModalPerubahan() {
      this.visiblePerubahan = false
    },
    showModalPengiriman() {
      this.visiblePengiriman = true
    },
    showModalPerubahan() {
      this.visiblePerubahan = true
    },
    clickDetail() {
      const collapse = this.$refs.refCollapse?.$el?.firstElementChild?.firstElementChild
      collapse && collapse.click()
    },
    getShippingAddressBackup() {
      this.loadingShippingAddressBackup = true
      this.controllerShipping = new AbortController()
      const { id } = this.sales
      const { business_id } = this.$route.query
      const bussinesIdbyList = this.sales?.business?.business_id
      getShippingOrderFromDashboard({
        signal: this.controllerShipping.signal,
        business_id: bussinesIdbyList || business_id || this.businessId,
        order_id: id,
      })
      .then(({ data }) => {
        this.shipping_items_backup = data || {}
      })
      .catch(() => {
        this.shipping_items_backup = null
      })
      .finally(() => {
        this.controllerShipping = null
        this.loadingShippingAddressBackup = false
      })
    },
    async onCollapse() {
      this.isShow = !this.isShow
      const { sales_items, id, channel, order_number } = this.sales
      if (this.isShow) {
        this.loadingDetail = true
        const promise = []
        const { business_id } = this.$route.query
        const bussinesIdbyList = this.sales?.business?.business_id
        const catalog_ids = (sales_items || []).map((item) => item.catalog_id)
        if (catalog_ids.length) {
          this.controllerOrderCatalog = new AbortController()
          promise.push(
            getOrderCatalog({
              signal: this.controllerOrderCatalog.signal,
              params: {
                business_id: bussinesIdbyList || business_id || this.businessId,
                catalogId: catalog_ids.join(','),
              },
            })
            .then(async res => {
              const data = res?.data?.data && res?.data?.data?.length
                ? res.data.data
                : await this.getInternalFulfillment(business_id, catalog_ids.join(','))
              this.sales_items = sales_items.map((item) => {
                const catalogs = data.find((val) => val.id === item.catalog_id)
                return {
                  ...item,
                  title: catalogs?.title || item.title,
                  catalog_sku: catalogs?.sku || item.catalog_sku,
                  // catalogs && Array.isArray(catalogs.images)
                  img_url: catalogs?.images?.[0] ? catalogs.images[0]?.imagePath || catalogs.images[0] : 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531',
                }
              })
            })
            .catch(async() => {
              const data = await this.getInternalFulfillment(business_id, catalog_ids.join(','))
              this.sales_items = sales_items.map((item) => {
                const catalogs = data.find((val) => val.id === item.catalog_id)
                return {
                  ...item,
                  title: catalogs?.title || item.title,
                  catalog_sku: catalogs?.sku || item.catalog_sku,
                  // catalogs && Array.isArray(catalogs.images)
                  img_url: catalogs?.images?.[0] ? catalogs.images[0]?.imagePath || catalogs.images[0] : 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531',
                }
              })
            })
            // eslint-disable-next-line
            .finally(() => { this.controllerOrderCatalog = null })
          )
        }

        this.controllerShipping = new AbortController()
        promise.push(
          getShippingOrder({
            signal: this.controllerShipping.signal,
            params: {
              business_id: bussinesIdbyList || business_id || this.businessId,
              order_id: id,
            },
          })
          .then((response) => {
            const res = (response?.data?.data || []).find((val) => val.order_id === id) || {}
            if (Object.keys(res).length > 0) {
              this.shipping_items = res
            } else {
              this.shipping_items = null
              this.getShippingAddressBackup()
            }
          })
          .catch(() => {
            this.shipping_items = null
            this.getShippingAddressBackup()
          }) // eslint-disable-next-line
          .finally(() => { this.controllerShipping = null })
        )

        promise.push(
          getFulfillmentDetailByOrder({
            signal: this.controllerShipping.signal,
            business_id: bussinesIdbyList || business_id || this.businessId,
            order_id: id,
          })
          .then(({ data: { data: response } }) => {
            if (response?.fulfillments?.length > 0) {
              this.fulfillmentData = response.fulfillments.at(0)
            } else {
              this.fulfillmentData = null
            }
          })
          .catch(() => {}) // eslint-disable-next-line
          .finally(() => { this.controllerShipping = null })
        )

        if (['distributor_redeem', 'distributor'].includes(this.sales?.channel?.code)) {
          promise.push(
            getFinanceDetail({
              order_id: id,
              channel_id: channel.id,
              business_id: business_id || this.$store.state.user.businessList[0].business_id || this.businessId,
            })
            .then(({ data: { data: response } }) => {
              this.sales = {
                ...this.sales,
                reason: response.reason,
                cancelled_reason: response.cancelled_reason,
                payment_data: response.payment_data,
              }
            })
            .catch(() => {}) // eslint-disable-next-line
            .finally(() => { this.controllerShipping = null })
          )

          if (this.sales?.credit_limit_usage) {
            promise.push(
              getDetailOrderCAD({
                order_id: id,
                channel_id: channel.id,
              })
              .then(({ data: { data: response } }) => {
                if (response) {
                  this.cadBillData = response
                } else {
                  this.cadBillData = null
                }
              })
              .catch(() => {
                this.cadBillData = null
              }),
            )
          }
        }

        this.$emit('onOpenCollapse', this.sales) // warehouse_id

        await Promise.all(promise)

        if (this.isShow) {
          this.loadingDetail = false
        }
      } else {
        if (this.controllerOrderCatalog) {
          this.controllerOrderCatalog.abort()
          this.controllerOrderCatalog = null
        }
        if (this.controllerShipping) {
          this.controllerShipping.abort()
          this.controllerShipping = null
        }
      }
    },
    handleCopy(type, val) {
      navigator.clipboard.writeText(val)
      this.$notification.success({
        message: `Copied ${type}: ${val}`,
      })
    },
    handleItemSubmitted(e) {
      this.$emit('handleItemSubmitted', {
        index: this.salesNum,
        checked: e.target.checked,
        id: this.sales.id,
      })
    },
    async getInternalFulfillment(business_id, catalog_id) {
      return await getCatalogFulfillment({ business_id: business_id || this.businessId, catalog_id })
        .then(({ data: { data: response } }) => response || [])
        .catch(() => [])
    },
  },
}
</script>

<style lang="scss">
.btn-order {
  padding: 0 !important;
  font-size: 12px !important;
  color: #1a1a1a !important;
  border-color: transparent !important;

  span {
    margin-left: 4px !important;
  }
}
.w-8 {
  flex-basis: 4% !important;
  max-width: 4% !important;
  -ms-flex-preferred-size: 4% !important;
}
.sales-checkbox .ant-checkbox-inner {
  border-color: #999 !important;
}
</style>

<style lang="scss" scoped>
.pca-sales-item:hover {
  border-color: #ff0a54;

  .ant-collapse-item {
    border-bottom-color: #ff0a54;
  }
}

.row-calc > .col {
  padding-left: 9px;
  padding-right: 9px;
  flex-basis: calc(100% / 6); // 100% / 8
  max-width: calc(100% / 6);
}

.col-10p {
  flex-basis: 10%;
  max-width: 10%;
}

.fs-10px {
  font-size: 10px !important;
}

.fs-12px {
  font-size: 12px !important;
}

.fs-13px {
  font-size: 13px !important;
}

.font-1 {
  font-family: Poppins; // font-face
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  white-space: initial;
}

.list-item {
  padding-left: 3px;
  margin-right: -1px;
}

.item {
  margin-bottom: 1.5rem;
  position: static;
  width: 100%;
  height: auto;
  background: #fff;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 10px;
  align-items: center;
  padding: 10px;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  cursor: pointer;
}
.custom_title_ship_text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 10px;
  white-space: initial;
}
.custom_title_with_icon {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  width: 70px;
}
.do_state {
  font-size: 8px;
  background-color: #d2d2d2;
  padding: 2px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  white-space: nowrap;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active di bawah versi 2.1.8 */ {
  opacity: 0;
}
.hr {
  border: none;
  border-top: 2px dotted #eee;
  color: #fff;
  background-color: #fff;
  height: 2px;
}

.red {
  color: red;
}

.refRowDetail {
  max-height: 75vh;
}

.order-list {
  .ant-card-body {
    padding: 0 !important;
    .title {
      padding: .5rem;
      border: 1px solid #d2d2d2;
      box-sizing: border-box;
      border-radius: 8px;
      color: #fff;

      &__content {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
      }
    }
    .content {
      padding: 1rem;
      font-family: Work Sans;
      font-style: normal;
      font-weight: 500;

      &__title-product {
        font-size: 14px;
        line-height: 16px;
      }

      &__product-support {
        font-size: 10px;
        line-height: 12px;
      }

      &__total-prices {
        font-size: 11px;
        line-height: 13px;
      }

      &__showed-product {
        font-size: 11px;
        line-height: 26px;
      }

      &__calculation {
        &__grand-total {
          background: #eaedec;
          padding: .75rem;
        }

        &__tabs {
          background: #eaedec;
        }

        &__detail-calculation {
          background: #eaedec;
          padding: .75rem;
          border-top: 1px solid #dfdfdf;
          font-family: Work Sans;
          font-style: normal;
          font-size: 11px;
          line-height: 13px;

          .title {
            font-weight: normal;
          }
        }
      }

      &__shipping,
      &__payment {
        &__title {
          font-family: Work Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}
.summary-order {
  display: flex;
  justify-content: space-between;
  p {
    margin: unset;
  }
}
.summary-order-promotion {
  color: red
}
.container-cancel-info {
  position: absolute;
  bottom: 0;
  color: var(--kit-color-primary);
  font-size: 10px;
  padding-bottom: 10px;
}
</style>

<style lang="scss">
.pca-sales-item.ant-collapse {
  .ant-collapse-content-box {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.text-break-word {
  word-wrap: break-word;
}
</style>
